<template>
  <div class="mobileMainScreen">

    <div class="buttonContainer">
        <div class="button" id="sendHSButton" v-on:click="startLocalFromMobile(23)">START</div>
    </div>      

  </div>
</template>

<script >
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";
  import axios from 'axios'; 

export default {
  name: "MobileStartLocal",
  props: ['id'],
  components: {
  },
  data() { return {
    }
  },

  created () {
    // call Api - show
    this.setBaseUrl("http://localhost:8100/")
  },

  mounted () {
  },

  computed:{
    ...mapGetters([
    ]),
  },

  beforeDestroy: function(){
  },

  methods: {
    ...mapActions([
      'startLocalFromMobile',
      'setBaseUrl',
    ]),

  },
}

</script>

<style scoped>

.noData {
    border: solid;
    border-color: red;
}

</style>
